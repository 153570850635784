@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

:root {
  --direction: var(--direction);
  --blue-primary-color: rgb(42, 52, 103);
  --primary-red-color: rgb(215, 19, 20);
  --global-align: "flex-start";
  --global-text-align: "start";
  --checkbox-padding: "0.25rem 0rem 0rem";
  --global-flex-direction: "row";
  --global-grid-direction: "ltr";
  --box-shadow-color: rgba(0, 0, 0, 0.25);
  --separator-color: rgba(185, 185, 185, 1);
  --white-color: rgba(255, 255, 255, 1);
  --dark-blue-color: rgba(10, 29, 53, 1);
  --light-white-color: rgba(225, 225, 225, 1);
  --font-family: "Poppins", sans-serif;
  --pop-up-color: rgba(219, 36, 39, 1);
  --input-border-color: rgba(102, 108, 139, 1);
  --disabled-input-field-color: rgba(239, 239, 239, 0.3);
  --input-placeholder-color: rgba(109, 109, 109, 1);
  --pop-up-background-color: rgba(18, 18, 18, 0.25);
  --unselected-step-color: rgba(227, 227, 227, 1);
  --list-black-font-color: rgb(12, 3, 3);
  --upload-btn-color: rgba(215, 19, 20, 0.56);
  --upload-secondary-font-color: rgba(91, 91, 91, 1);
  --upload-preview-font-color: rgba(85, 85, 85, 1);
  --legal-text-color: rgba(60, 57, 80, 1);
  --btn-hover-color: rgba(229, 99, 100, 1);
  --btn-clicked-color: rgba(188, 17, 18, 1);
  --page-background-color: rgba(240, 238, 241, 1);
  --unselected-nav-link: rgba(89, 97, 136, 1);
  --selected-menu-item-color: rgba(208, 210, 222, 1);
  --menu-item-border-color: rgba(154, 154, 154, 1);
  --search-input-border-color: rgba(42, 52, 103, 0.5);
  --blue-table-color: rgb(192, 190, 207);
  --border-line-color: rgba(217, 217, 217, 1);
  --order-action-button-color: rgba(233, 123, 123, 1);
  --order-pagination-color: rgba(116, 116, 116, 1);
  --order-pagination-number-color: rgba(253, 253, 245, 1);
  --filter-title-border-color: rgba(38, 57, 108, 0.36);
  --create-order-option-selected-color: rgba(42, 52, 103, 0.22);
  --tooltip-font-color: rgba(58, 58, 58, 1);
  --sub-text-schedule-time-input-color: rgba(191, 191, 191, 1);
  --snackbar-background-color: rgba(84, 193, 89, 1);
  --navbar-height: 4.25rem;
  --sidebar-width: 15.25rem;
  --disabled-button-color: rgba(114, 114, 114, 1);
  --map-search-input-color: rgba(237, 237, 237, 1);
  --active-map-label-color: rgb(123, 179, 51);
  --inactive-map-label-color: rgb(215, 19, 20);
  --cross-color: rgba(42, 52, 103, 0.141);
  --container-box-shadow-color: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.251);
  --show-history-button-color: rgb(242, 243, 246);
  --outer-circle-indicator-color: rgba(167, 167, 167, 0.251);
  --inner-circle-indicator-color: rgb(152, 152, 152);
  --line-indicator-color: rgb(233, 233, 233);
  --outer-circle-red-indicator-color: rgba(215, 19, 20, 0.251);
  --driver-contact-button-color: rgba(42, 52, 103, 0.059);
  --draft-chart-color: #f1f1f1;
  --incomplete-data-chart-color: #ffe1d6;
  --awaiting-selection-chart-color: #dbedff;
  --pending-pickup-chart-color: #f9ecd8;
  --pickUp-started-chart-color: #fce2ef;
  --arrived-at-pickup-chart-color: #fff9d6;
  --collected-pending-delivery-chart-color: #d6f7f8;
  --out-for-delivery-started-chart-color: #d6dfed;
  --arrived-at-drop-off-chart-color: #e1ebf3;
  --delivered-chart-color: #dff1e3;
  --cancelled-chart-color: #f47676;
  --on-hold-chart-color: #ffedd6;
  --rescheduled-chart-color: #e1e5e6;
  --background-gray: rgb(225, 224, 224);
}

* {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
